import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"
import Customer from '../../components/CustomerList'

export default () => (
     
  <Layout>

    <SEO title="Um okkur"
        description="" 
        pathname="/about/"
    />
     
    
    <Hero 
         headerText="Um okkur" 
         subHeaderText="Getlocal er hugbúnaðarhús sem sérhæfir sig í ferðaþjónustu"
         /> 

    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content intro">            
          
          <p>Verkefnið er leitt af Getlocal ehf. en Getlocal er hugbúnaðarhús sem sérhæfir sig í þjónustu fyrir fyrirtæki í ferðaþjónustu.</p>
          
          <p>Síðastliðin ár hefur Getlocal verið að þróa vefsölukerfi sem í dag eru nýtt af ferðaþjónustufyrirtækjum í yfir 15 löndum og tengist beint við birgðakerfi (eins og Bókun). Hugbúnaður er tilbúinn til notkunar og teljum við okkur í þeirri stöðu að geta sett upp svona vef á skömmum tíma með litlum tilkostnaði.</p>

          <p>Meðal viðskiptavina Getlocal er mörg af stærri ferðaþjónustufyrirtækjum landsins sem og erlendis en fyrirtæki í yfir 15 löndum nota tækni Getlocal til að selja ferðir og afþreyingu á netinu.</p>

          <div className="columns is-multiline is-vcentered">
                <Customer imgSrc="/img/grayline.png" altText="Gray Line" website="https://grayline.is" />
                
                <Customer imgSrc="/img/rss.png" altText="Reykjavik Sightseeing" website="https://rss.is" />
                <Customer imgSrc="/img/bustravel.png" altText="Bustravel Iceland" website="https://bustravel.is" />
                <Customer imgSrc="/img/airportdirectlogo.jpg" altText="Airport Direct" website="https://airportdirect.is" />
                <Customer imgSrc="/img/icelandguidedtours.png" altText="Iceland Guided Tours" website="https://igtours.is" />
                <Customer imgSrc="/img/bigescapelogo.svg" altText="The Big Escape" website="https://thebigescape.com" />
                <Customer imgSrc="/img/nicetravel.png" altText="Nice Travel" website="https://nicetravel.is" />
                <Customer imgSrc="/img/yourdaytours.jpg" altText="Your Day tours" website="https://yourdaytours.is" />
                <Customer imgSrc="/img/whale-safari.png" altText="Whale Safari" website="https://whalesafari.is" />  
                <Customer imgSrc="/img/icelandhorizon.png" altText="Iceland Horizon" website="https://icelandhorizon.is" />
                <Customer imgSrc="/img/nl.png" altText="Northern Lights Bus" website="https://northernlightsbus.is" />
                <Customer imgSrc="/img/solhestar.png" altText="Sólhestar" website="https://solhestar.is" />
                <Customer imgSrc="/img/sterna.png" altText="Sterna Travel" website="https://sternatravel.com" />
                
                <Customer imgSrc="/img/private.png" altText="Private Excursions" website="https://privateiceland.is" />
                <Customer imgSrc="/img/reykjavikerupts.png" altText="Reykjavik Erupts" website="https://reykjavikerupts.is" />
                <Customer imgSrc="/img/inspiration-iceland.jpg" altText="Inspiration Iceland" website="https://inspiration-iceland.com" />
                <Customer imgSrc="/img/icelandintro.png" altText="Iceland Intro" website="https://icelandintro.is" />    
                <Customer imgSrc="/img/smartbus.png" altText="Smart Bus" website="https://smartbus.is" />
                <Customer imgSrc="/img/grapevine.png" altText="GoTravel by Grapevine" website="https://gotravel.grapevine.is" />
                <Customer imgSrc="/img/icelandexcursions.png" altText="Iceland Excursions" website="https://icelandexcursions.is" />
                <Customer imgSrc="/img/icelandbybus.png" altText="Iceland By Bus" website="https://icelandbybus.is" /> 
                <Customer imgSrc="/img/mrpuffin.jpg" altText="Mr Puffin" website="https://puffintours.is" />

                <Customer imgSrc="/img/flight-centre.png" altText="Flight Centre" website="https://activities.flightcentre.co.za" />
                <Customer imgSrc="/img/boardaboat.png" altText="Board a boat" website="https://boardaboat.com" />
                <Customer imgSrc="/img/daytrip4u.png" altText="Day Trip 4U" website="https://daytrip4u.com" />

                <Customer imgSrc="/img/mpc.png" altText="Musical Pub Crawl" website="https://musicalpubcrawl.com" />   
                          
                <Customer imgSrc="/img/moafrika.png" altText="Mo Afrika Tours" website="https://moafrikatours.com" />
                <Customer imgSrc="/img/daytrip4u.png" altText="Samba Tours" website="https://samba.tours" />

                <Customer imgSrc="/img/savethegroom.svg" altText="Save the Groom" website="https://savethegroom.com" />
                <Customer imgSrc="/img/rioadventures.png" altText="Rio Adventures" website="https://rioadventures.com" />
                <Customer imgSrc="/img/resfeber.png" altText="Resfeber Travel" website="https://resfebertravel.com/" />
                <Customer imgSrc="/img/tenerifefun.png" altText="Tenerife Fun" website="https://tenerife.fun/" />

                <Customer imgSrc="/img/beasalocal.png" altText="Be As a local" website="https://beasalocal.com" />
                <Customer imgSrc="/img/visit-crete.png" altText="Visit Crete" website="https://www.visitcrete.co.uk" />
                
                <Customer imgSrc="/img/kruger-to-cape.png" altText="Kruger to Cape" website="https://activities.krugertocape.co.za" />
                <Customer imgSrc="/img/travelcheck.png" altText="Travel Check" website="https://experiences.travelcheck.co.za" />
                <Customer imgSrc="/img/glorious-cape-tours.png" altText="Glorious Cape Tours" website="https://gloriouscapetours.co.za" />
                <Customer imgSrc="/img/privatebelize.png" altText="Private Belize Adventures" website="https://www.privatebelizeadventures.com" />

                <Customer imgSrc="/img/endless-weekend.png" altText="Endless Weekend" website="https://endlessweekend.com" />
                
                
                
                <Customer imgSrc="/img/visit-cyprus.png" altText="Visit Cyprus" website="https://www.visitcyprus.co.uk" />

                <Customer imgSrc="/img/things2do.png" altText="Things 2 Do" website="https://things2do.eu" />
                        
                <Customer imgSrc="/img/laugavegur.png" altText="Laugavegur Trail" website="https://laugavegur.is" />
                
                
                

            </div>
          
        </div>

       
      </div>
    
    </section>

  </Layout>
)
